@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600&display=swap");

.gradient-border {
	border-top: 1px solid #6996db;
	border-bottom: 1px solid #a849d0;
	border-left: 1px solid #6996db;
	border-right: 1px solid #a849d0;
	background-image: linear-gradient(#6996db, #a849d0),
		linear-gradient(#6996db, #a849d0);
	background-size: 0px 100%;
	background-position: 0 0, 100% 0;
	background-repeat: no-repeat;
}
.css-1ps6pg7-MuiPaper-root{
	box-shadow: none !important;
}
.pad-left-50{
	padding-left: 50px;
}
input{
	border-bottom: 1px solid #6996db;
	width: 25%;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 10px;
}
input:focus{
	border-bottom: 1px solid #6996db;
}

input[type="checkbox"] + label span {
	transition: background 0.2s, transform 0.2s;
}

input[type="checkbox"]:checked + label span {
	background: rgba(255, 255, 255, 0.56);
	box-shadow: 0px 0px 0px 4px #262266b6 inset;
}

.myscroller::-webkit-scrollbar {
	width: 6px;
	border-radius: 10px;
	background-color: #000000;
}
.myscroller::-webkit-scrollbar-thumb {
	background-color: #fefefe;
	border-radius: 10px;
}
.myscroller::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f536;
}

@font-face {
  font-family: 'Impact';
  src: local('Impact'), url(./components/assets/impact.ttf) format('truetype');
}

.loader {
  overflow: hidden;
  margin: 0 !important;
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: column;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000000000 !important;
  color: white;
}
